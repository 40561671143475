<template>
<!-- 商品列表 -->
<div class="box100">
  <div class="whiteBg" v-if="!showForm">
    <template v-if="roleBtn[0].power">
      <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
    </template>
    <div class="flexAC" style="margin: 16px 0px;">
      <el-input v-model="gcname"  placeholder="请输入名称/一级分类/二级分类" class="inputSearch"></el-input>
      <el-select v-model="gcname1" placeholder="商品一级分类"  class="retSelect">
        <el-option v-for="item in gcList" :key="item.gcid" :label="item.gcname" :value="item.gcid"></el-option>
      </el-select>
      <el-select v-model="gtname" placeholder="商品二级分类"  class="retSelect">
        <el-option v-for="item in gtList" :key="item.gtid" :label="item.gtname" :value="item.gtid"></el-option>
      </el-select>
      <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
      <el-button class="btnReset" @click="handleReset">重置</el-button>
    </div>
    <div class="table_list">
      <el-table
        :header-cell-style="{background:'#EBF2FE'}"
        :data="comdList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index"
          width="200">
        </el-table-column>
        <el-table-column
          label="商品名称"
          prop="gdname">
        </el-table-column>
        <el-table-column
          label="商品规格"
          prop="gdspec">
        </el-table-column>
        <el-table-column
          label="商品一级分类"
          prop="gcname">
        </el-table-column>
        <el-table-column
          label="商品二级分类"
          prop="gtname">
        </el-table-column>
        <el-table-column
          label="商品单位"
          prop="guname">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <template v-if="roleBtn[2].power">
              <el-button type="text" size="small" @click="showEdit(scope.row)">编辑</el-button>
            </template>
            <template v-if="roleBtn[3].power">
              <el-button type="text" size="small" @click="handDetail(scope.row)">明细</el-button>
            </template>
            <template v-if="roleBtn[1].power">
              <el-button type="text" size="small" style="color: red;" @click="handDel(scope.row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="pageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>
    <!-- 展示明细 -->
    <el-dialog title=明细 :visible.sync="fromShow" center width="600px" class="gyDialog" :close-on-click-modal="false" >
      <div class="flexF un_info">
        <img :src="info.furl" :onerror="errorImg" class="uni_img"/>
        <div class="udp">
          <p class="udi">商品名称: <span>{{info.gdname}}</span></p>
          <p class="udi">商品规格: <span>{{info.gdspec}}</span></p>
          <p class="udi">商品类型: <span>{{info.gcname}}{{info.gtname}}</span></p>
          <p class="udi">商品单位: <span>{{info.guname}}</span></p>
          <p class="udi">商品品牌: <span>{{info.gbname}}</span></p>
          <p class="udi">参考售价: <span>{{info.gdprice}}</span></p>
          <p class="udi">提成方式: <span>{{info.gdsway}}</span></p>
        </div>
      </div>
    </el-dialog>
  </div>
  <!-- 新增编辑 -->
  <div class="box100" v-if="showForm">
    <ComdForm @formCancel="formCancel" :comInfo="comInfo" @formSuccess="formSuccess"></ComdForm>
  </div>
</div>
</template>

<script>
import ComdForm from '@/components/commodity/comdForm'
import { goodinfoGet, goodlevelDrop, goodtypeDrop, goodinfoDelete } from '@/api/commodity'
export default {
  name: 'commodityList',
  components: {
    ComdForm
  },
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      comdList: [],
      showForm: false,
      gcname: '',
      gcname1: '',
      gcList: [],
      gtname: '',
      gtList: [],
      fromShow: false,
      info: {},
      errorImg: 'this.src="' + require('../../assets/img/users.png') + '"',
      comInfo: null
    }
  },
  created () {
    this.init()
    this.getInit()
  },
  methods: {
    init () {
      const params = {
        gcname: this.gcname,
        page_index: this.page,
        page_size: this.size
      }
      goodinfoGet(params).then(res => {
        if (res.status === 1) {
          this.comdList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.comdList = []
          this.total = 0
        }
      })
    },
    getInit () {
      // 一级分类下拉
      goodlevelDrop().then(res => {
        if (res.status === 1) {
          this.gcList = res.data.data
        } else {
          this.gcList = []
        }
      })
      // 二级分类下拉
      goodtypeDrop().then(res => {
        if (res.status === 1) {
          this.gtList = res.data.data
        } else {
          this.gtList = []
        }
      })
    },
    // 新增
    handleAdd () {
      this.showForm = true
      this.comInfo = null
    },
    // 展示【编辑】
    showEdit (row) {
      this.showForm = true
      this.comInfo = { ...row }
    },
    formCancel () {
      this.showForm = false
    },
    // 商品信息提交成功
    formSuccess () {
      this.showForm = false
      this.init()
    },
    // 展示明细
    handDetail (row) {
      this.fromShow = true
      this.info = row
    },
    // 点击【删除】
    handDel (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        goodinfoDelete({ gdid: row.gdid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
     // 搜索
    handleSearch () {
      this.init()
    },
    // 重置
    handleReset () {
      this.gcname = ''
      this.gcname1 = ''
      this.gtname = ''
      this.init()
    },
    // 翻页
    pageChange (page) {
      this.page = page
      this.init()
    },
  }
}
</script>

<style scoped>
.udp {
  margin-left: 30px;
}
.udi {
  font-size: 14px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #A5B7CC;
  margin-bottom: 10px;
}
.udi span {
  color: #333333;
}
.uni_img{
  width: 120px;
  height: 120px;
  border-radius: 10px;
}
.un_info {
  padding: 0 50px;
}
</style>
