<template>
  <div class="upload_box">
    <el-upload
      class="uploader"
      :action="action"
      accept=".png,.jpeg,.bmp,.gif,.jpg,.PNG,.JPG,.JPEG,.GIF"
      :headers="headers"
      :show-file-list="false"
      :disabled="disabled === undefined ? false : disabled"
      :before-upload="beforeAvatarUpload"
      :on-success="handleAvatarSuccess">
      <img v-if="imageUrl" :src="imageUrl" class="avatar">
      <div v-else class="demo-image-error">
        <el-image>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <span class="tip">请上传要展示的图片</span>
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'upload',
  props: ['refresh', 'disabled', 'picture', 'fileType'],
  data () {
    return {
      action: this.BASE_URL + this.$url.PATH.annex,
      loading: false,
      imageUrl: ''
    }
  },
  computed: {
    headers () {
      return {
        Authorization: window.localStorage.getItem('token'),
        loginway: 'pc',
        sign: this.$url.PATH.sign
      }
    }
  },
  watch: {
    picture (val) {
      if (this.picture === undefined || this.picture === '' || this.picture === null) {
        this.imageUrl = ''
      } else {
        this.imageUrl = this.picture
      }
    },
    refresh (val) {
      if (this.picture === undefined || this.picture === '' || this.picture === null) {
        this.imageUrl = ''
      } else {
        this.imageUrl = this.picture
      }
    }
  },
  created () {
    if (this.picture === undefined || this.picture === '' || this.picture === null) {
      this.imageUrl = ''
    } else {
      this.imageUrl = this.picture
    }
  },
  methods: {
    beforeAvatarUpload (file) {
      const type = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const isJPG = 'jpeg/bmp/gif/jpg/PNG/JPG/JPEG/GIF'.includes(type)
      const isJPG = this.fileType.includes(type)
      const isLt50M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('请上传支持的图片类型文件')
        return false
      } else if (!isLt50M) {
        this.$message.error('上传图片大小不能超过 5MB!')
        return false
      } else {
        this.loading = true
        return isJPG && isLt50M
      }
    },
    handleAvatarSuccess (res) {
      if (res.status === 1) {
        // this.loading = false
        this.imageUrl = res.data.furl
        this.$emit('uploadSuccess', res.data)
      } else {
        this.$message.error(res.message)
      }
    },
    cancelImg() {
      this.imageUrl = ''
    }
  }
}
</script>

<style scoped>
.uploader,.el-upload{
  width: 180px;
  height: 83px;
  border: 1px solid #A5B7CC;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  /* background: #F7F9FB; */
}
.uploader .el-upload:hover {
  border-color: #A5B7CC;
}
.demo-image-error .el-image {
  font-size: 25px;
  text-align: center;
  color: #A5B7CC;
  padding: 20px 78px;
}
.avatar-uploader-icon {
  font-size: 20px;
  color: #A5B7CC;
  width: 105px;
  height: 105px;
  line-height: 80px;
  text-align: center;
  font-weight: 600;
}
.avatar{
  width: 100%;
  height: 100%;
}
.tip{
  width: 100%;
  position: absolute;
  top: 60%;
  left: 0;
  color: #A5B7CC;
  font-size: 12px;
}
</style>
