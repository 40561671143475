import { post } from '@/http/http'

// 商品单位查询
export const goodunitGet = p => post('api/v1.0/goodsinfo/goodsinfo_get_unit', p)

// 商品单位新增
export const goodunitAdd = p => post('api/v1.0/goodsinfo/goodsinfo_add_unit', p)

// 商品单位编辑
export const goodunitUpdate = p => post('api/v1.0/goodsinfo/goodsinfo_update_unit', p)

// 商品单位删除
export const goodunitDelete = p => post('api/v1.0/goodsinfo/goodsinfo_delete_unit', p)


// 商品品牌查询
export const goodbrandGet = p => post('api/v1.0/goodsinfo/goodsinfo_get_brand', p)

// 商品品牌添加
export const goodbrandAdd = p => post('api/v1.0/goodsinfo/goodsinfo_add_brand', p)

// 商品品牌编辑
export const goodbrandUpdate = p => post('api/v1.0/goodsinfo/goodsinfo_update_brand', p)

// 商品品牌查询
export const goodbrandDelete = p => post('api/v1.0/goodsinfo/goodsinfo_delete_brand', p)


// 商品分类查询
export const goodsortGet = p => post('api/v1.0/goodsinfo/goodsinfo_get_class', p)

// 商品一级分类新增
export const goodlevelAdd = p => post('api/v1.0/goodsinfo/goodsinfo_add_class', p)

// 商品一级分类编辑
export const goodlevelUpdate = p => post('api/v1.0/goodsinfo/goodsinfo_update_class', p)

// 商品一级分类删除
export const goodlevelDelete = p => post('api/v1.0/goodsinfo/goodsinfo_delete_class', p)

// 商品二级分类新增
export const goodtypeAdd = p => post('api/v1.0/goodsinfo/goodsinfo_add_type', p)

// 商品二级分类编辑
export const goodtypeUpdate = p => post('api/v1.0/goodsinfo/goodstype_update_class', p)

// 商品二级分类删除
export const goodtypeDelete = p => post('api/v1.0/goodsinfo/goodsinfo_delete_type', p)

// 商品一级分类是否展示
export const goodlevelIsok = p => post('api/v1.0/goodsinfo/goodsinfo_isok_class', p)

// 商品二级分类是否展示
export const goodtypeIsok = p => post('api/v1.0/goodsinfo/goodsinfo_isok_type', p)


// 商品信息查询
export const goodinfoGet = p => post('api/v1.0/goodsinfo/goodsinfo_get_info', p)

// 商品信息添加
export const goodinfoAdd = p => post('api/v1.0/goodsinfo/goodsinfo_add_info', p)

// 商品信息编辑
export const goodinfoUpdate = p => post('api/v1.0/goodsinfo/goodsinfo_update_info', p)

// 商品信息删除
export const goodinfoDelete = p => post('api/v1.0/goodsinfo/goodsinfo_delete_info', p)

// 商品一级分类（下拉）
export const goodlevelDrop = p => post('api/v1.0/goodsinfo/goods_gcid_get_down', p)

// 商品二级分类（下拉）
export const goodtypeDrop = p => post('api/v1.0/goodsinfo/goods_gtid_get_down', p)

// 商品单位（下拉）
export const goodunitDrop = p => post('api/v1.0/goodsinfo/goods_guid_get_down', p)

// 商品品牌（下拉）
export const goodbrandDrop = p => post('api/v1.0/goodsinfo/goods_gbid_get_down', p)
