<template>
  <!-- 商品列表新增 -->
  <div class="detailForm">
    <div class="formItem">
        <p class="formTitle">基本信息</p>
        <el-form :model="comInfoForm" :rules="comInfoRule" ref="comInfo">
          <div class="flexCB">
            <el-form-item label="商品名称" class="detailFormItem"  prop="gdname">
              <el-input v-model="comInfoForm.gdname"  placeholder="请输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label="商品规格" class="detailFormItem"  prop="gdspec">
              <el-input v-model="comInfoForm.gdspec"  placeholder="请输入商品规格"></el-input>
            </el-form-item>
          </div>
          <div class="flexCB">
            <div class="detailFormItem flexCB">
              <el-form-item label="商品类型" prop="gcid" class="sfItem">
                <el-select v-model="comInfoForm.gcid" placeholder="商品一级分类"  style="margin-right:20px;" @change="oneChange">
                  <el-option v-for="item in gcList" :key="item.gcid" :label="item.gcname" :value="item.gcid"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="gtid" class="sfItem" style="margin-bottom: -3px;">
                <el-select v-model="comInfoForm.gtid" placeholder="商品二级分类" >
                  <el-option v-for="item in gtList" :key="item.gtid" :label="item.gtname" :value="item.gtid"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item label="商品单位" class="detailFormItem"  prop="guid">
              <el-select v-model="comInfoForm.guid" placeholder="请选择商品单位" class="retItem">
                <el-option v-for="item in guList" :key="item.guid" :label="item.guname" :value="item.guid"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="flexCB">
            <el-form-item label="选择品牌" class="detailFormItem"  prop="gbid">
              <el-select v-model="comInfoForm.gbid" placeholder="请选择商品品牌" class="retItem">
                <el-option v-for="item in gbList" :key="item.gbid" :label="item.gbname" :value="item.gbid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="参考售价（元）" class="detailFormItem"  prop="gdprice">
              <el-input v-model="comInfoForm.gdprice" placeholder="请输入参考售价"></el-input>
            </el-form-item>
          </div>
          <div class="flexCB">
            <el-form-item label="提成方式" prop="gdsway" class="sfItem">
              <label>固定提成金额</label><el-input v-model="comInfoForm.gdsway" placeholder="请输入提成金额"></el-input>
            </el-form-item>
            <el-form-item label="商品照片"  prop="gdimage" class="sfItem">
              <template style="width: 100%;">
                <Upload :fileType="'jpg/png/jpeg'" :refresh="refresh" @uploadSuccess="addImgSuccess" :picture="comInfoForm.furl"></Upload>
              </template>
            </el-form-item>
          </div>
        </el-form>

        <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
          <div class="settingCancel" @click="FromCancel">取消</div>
          <div class="settingConfirm" @click="FromConfirm">确定</div>
        </div>
      </div>
  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import { goodinfoAdd, goodinfoUpdate, goodlevelDrop, goodtypeDrop, goodunitDrop, goodbrandDrop } from '@/api/commodity'
export default {
  components: {
    Upload
  },
  props: ['comInfo'],
  data () {
    const checkGroup = (rule, value, callback) => {
      if (this.comInfoForm.gcid !== '') {
        if (this.gtList.length !== 0) {
          if (value === '') {
            return callback(new Error('请选择二级分类'))
          }
        }
      }
      callback()
    }
    const moneyReg = (rule,value,callback) => {
      if(value.length > 10){
        callback(new Error('长度在 0 到 10 个字符'));
      }else{
        setTimeout(() => {
          if (!/^\d+[.]?\d{0,2}$/.test(value) && value){
            callback(new Error('请输入正整数或小数保留两位小数'));
          }else{
            callback()
          }
        }, 500);
      }
    };
    return {
      refresh: false,
      comInfoForm: {
        gdname: '', // 商品名称
        gdspec: '', // 商品规格
        gcid: '', // 商品一级分类ID
        gtid: '', // 商品二级分类ID
        guid: '', // 商品单位ID
        gbid: '', // 商品品牌ID
        gdprice: '', // 商品参考售价
        gdsway: '', // 商品提成
        gdimage: '', // 商品照片url
        guname: '', // 商品单位名称
        gbname: '' // 商品品牌名称
      },
      comInfoRule: {
        gdname: [
          { required: true, trigger: 'blur', message: '商品名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        gdspec: { required: true, trigger: 'blur', message: '商品规格不能为空' },
        gcid: { required: true, trigger: 'blur', message: '商品一级分类名称不能为空' },
        gtid: { required: true, validator: checkGroup, trigger: 'blur' },
        guid: { required: true, trigger: 'blur', message: '商品单位不能为空' },
        gbid: { required: true, trigger: 'blur', message: '商品品牌不能为空' },
        gdprice: [{ required: true, trigger: 'blur', message: '参考售价不能为空' },{ validator: moneyReg, trigger: 'blur' }],
        gdsway: [{ required: true, trigger: 'blur', message: '提成方式不能为空' },{ validator: moneyReg, trigger: 'blur' }],
        gdimage: { required: true, trigger: 'blur', message: '商品图片不能为空' }
      },
      gcList: [],
      gtList: [],
      guList: [],
      gbList: []
    }
  },
  created () {
    if (this.comInfo != null) {
      this.comInfoForm = this.comInfo
    }
    this.initDown()
    this.getGroupDown()
  },
  methods: {
    initDown () {
      // 商品单位（下拉）
      goodunitDrop().then(res => {
        if (res.status === 1) {
          this.guList = res.data.data
        } else {
          this.guList = []
        }
      })
      // 商品品牌（下拉）
      goodbrandDrop().then(res => {
        if (res.status === 1) {
          this.gbList = res.data.data
        } else {
          this.gbList = []
        }
      })
    },
    // 商品一级分类
    getGroupDown () {
      goodlevelDrop().then(res => {
        if (res.status === 1) {
          this.gcList = res.data.data
          if (this.comInfo !== null) {
            this.oneChangeEdit(this.comInfo.gcid)
          }
        }
      })
    },
    // 商品二级分类
    oneChange (val) {
      this.gtList = []
      this.comInfoForm.gtid = ''
      goodtypeDrop({ gcid: val }).then(res => {
        if (res.status === 1) {
          this.gtList = res.data.data
        }
      })
    },
    // [编辑表单]商品二级分类
    oneChangeEdit (val) {
      goodtypeDrop({ gcid: val }).then(res => {
        if (res.status === 1) {
          this.gtList = res.data.data
        }
      })
    },
    addImgSuccess (res) {
      this.comInfoForm.gdimage = res.fid
    },
    // 确认
    FromConfirm () {
      this.$refs.comInfo.validate((valid) => {
        if (!valid) return
        const params = {
            gdname: this.comInfoForm.gdname,
            gdspec: this.comInfoForm.gdspec,
            gcid: this.comInfoForm.gcid,
            gtid: this.comInfoForm.gtid,
            guid: this.comInfoForm.guid,
            gbid: this.comInfoForm.gbid,
            gdprice: this.comInfoForm.gdprice,
            gdsway: this.comInfoForm.gdsway,
            gdimage: this.comInfoForm.gdimage
          }
        if (this.comInfo === null) {
          goodinfoAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$emit('formSuccess')
            } else {
              this.$message.error(res.message)
            }
          })
        } else  {
          const params = {
            gdid: this.comInfoForm.gdid,
            gdname: this.comInfoForm.gdname,
            gdspec: this.comInfoForm.gdspec,
            gcid: this.comInfoForm.gcid,
            gtid: this.comInfoForm.gtid,
            guid: this.comInfoForm.guid,
            gbid: this.comInfoForm.gbid,
            gdprice: this.comInfoForm.gdprice,
            gdsway: this.comInfoForm.gdsway,
            gdimage: this.comInfoForm.gdimage
          }
          goodinfoUpdate(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$emit('formSuccess')
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    FromCancel () {
      this.$emit('formCancel')
    },
  }
}
</script>

<style>
.formTitle{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.sfItem {
  width: 47%;
}
.retItem {
  width: 100%;
}
.retItem .el-input__inner {
  height: 40px;
}
.showImg >>> .el-form-item__label{
  width: 100%;
  text-align: left;
}
.el-form-item .el-form-item__label {
  width: 100%;
  text-align: left;
}
</style>
